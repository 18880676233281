<!-- 当前用户接受的需求 -->
<template>
  <div class="tab">
    <div class="tab_table">
      <el-table
        :data="tableData"
        :header-cell-style="headerCellStyle"
        @row-click="rowClick"
        v-loading="loading">
        <el-table-column
          label="订单编号"
          prop="orderNo">
        </el-table-column>
        <el-table-column
          label="需求名称"
          prop="title">
        </el-table-column>
        <el-table-column
          label="需求类型"
          prop="serviceTypeName">
        </el-table-column>
        <!-- <el-table-column
          label="价格"
          prop="budget">
          <template slot-scope="scope">
            <span class="tw-font-bold tw-text-primary-text">
              {{ scope.row.budget === '面议' ? '面议' : scope.row.budget + '元' }}
            </span>
          </template>
        </el-table-column> -->
        <el-table-column
          label="报价"
          prop="totalAmount">
          <template slot-scope="scope">
            <span class="tw-font-bold tw-text-primary-text">
              {{ scope.row.totalAmount }}元
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="状态">
          <template slot-scope="scope">
            <el-tag :type="elTags(scope.row).type">
              {{elTags(scope.row).label}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="下单时间"
          prop="createdAt">
        </el-table-column>
      </el-table>
    </div>
    <div class="tab_pagination tw-flex tw-justify-center tw-items-center">
      <el-pagination
        hide-on-single-page
        background
        @current-change="pageNumChange"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        layout="prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import globalVar from '@/configs/globalVar'

export default {
  data () {
    return {
      loading: false,
      tableData: [],
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      } // 分页对象
    }
  },
  computed: {
    headerCellStyle () {
      return {
        backgroundColor: '#F7F8F9',
        color: '#909399',
        fontWeight: 'bold'
      }
    },
    elTags () {
      return (item) => {
        return {
          type: {
            [globalVar.REQUIRE_ORDER_STATUS_1]: 'success',
            [globalVar.REQUIRE_ORDER_STATUS_2]: 'info'
          }[item.logicStatus],
          label: {
            [globalVar.REQUIRE_ORDER_STATUS_1]: '对接中',
            [globalVar.REQUIRE_ORDER_STATUS_2]: '已完成'
          }[item.logicStatus]
        }
      }
    }
  },
  mounted () {
    this.getTableData(1)
  },
  methods: {
    getTableData (pageNum) {
      this.loading = true
      api.getMyRequireOrderList({
        pageNum,
        pageSize: this.pagination.pageSize
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    pageNumChange (page) {
      this.getTableData(page)
    },
    rowClick (row, column, event) {
      const { href } = this.$router.resolve({
        path: `/require-order/order-detail/${row.id}`
      })

      window.open(href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.tab {
  &_op {
    padding: 10px 0;
  }
  &_table {
    padding: 10px 0;
  }
  &_pagination {
    margin: 20px 0;
    height: 40px;
  }
}
</style>
